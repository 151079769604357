import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DsLibraryModule } from '@crowdfarming/ds-library';
import { TranslocoModule } from '@ngneat/transloco';
import { InfoItemComponent } from '../info-list/info-item/info-item.component';
import { InfoListComponent } from './info-list.component';

@NgModule({
  declarations: [InfoListComponent, InfoItemComponent],
  imports: [CommonModule, TranslocoModule, DsLibraryModule],
  exports: [InfoListComponent, InfoItemComponent],
})
export class InfoListModule {}
